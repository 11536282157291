<template>
	<div class="main-contents">
		<div class="mem_container">
			<div class="main-box">
				<div class="mem_contents_header account">
					<div class="title-txt">아이디 찾기<br/><span>아이디를 분실 하셨나요?</span></div>
				</div>
				<div class="mem_contents_body">
					<div class="info-box">
						<div class="phone-img account top">
							수요기업 계정 선택
							<div class="select-box">
								<input type="radio" class="account" name="account" v-model="mberDivCd" id="00" value="21">
								<label for="00">
									<span class="rd"></span>
									관리 총괄
								</label>
								<span class="account">/</span>
								<input type="radio" class="account" name="account" v-model="mberDivCd" id="01" value="22">
								<label for="01">
									<span class="rd"></span>
									프로젝트 담당
								</label>
							</div>
							<div class="phone-info account">
								선택하신 수요기업 계정으로 아이디찾기를 진행하시겠습니까?
							</div>
						</div>
						<div class="phone-btn account top">
							<div class="btn-prv" @click="pageMove('backward')">뒤로</div>
							<div class="btn-crt" @click="pageMove('forward')">다음</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			mberDivCd : '21'
		}
	},

	methods : {
		pageMove(div) {
			var scrNm = 'MEM002M01';
			if(div == 'backward') scrNm = 'MEM002M06';

			this.$router.push({name:scrNm, params:{mberDivCd:this.mberDivCd}});
		}
	}
}
</script>